import { Component, OnInit, Inject } from '@angular/core';
import { Router } from "@angular/router";
import { ApiCallingService } from '../../apiCalling.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-fuel',
  templateUrl: './fuel.component.html',
  styleUrls: ['./fuel.component.css'],
  providers:[ApiCallingService]
})
export class FuelComponent implements OnInit {

  today=new Date();
  step = 0;
  Partys: any[] = [];
  Vehicles: any[] = [];
  table_path = "";
  vehicle_name_to_send: any = "0";
  party_name_to_send: any = "0";
  message: string;
  validation = 1;
  valid: number = 1;
  
  constructor(private datePipe: DatePipe, private router: Router, private apicall: ApiCallingService) {}

  sendData(fromDate, toDate, partyName, vehicleName){
    console.log( fromDate, toDate, partyName, vehicleName );
    if (partyName ==0 && vehicleName == 0) {
      this.message = "Party or Vehicle is required";
      this.validation = 0;
      return;
    }
    var fromD = fromDate.split("-").reverse().join("-");
    var toD = toDate.split("-").reverse().join("-");
    if( toD < fromD){
      this.message = "From is greater than To";
      this.valid = 0;
      return;
   }
    let body = JSON.stringify({
      "cross_key":"1",
      "vehicle_code":vehicleName,
      "party_code":partyName,
      "from_date":fromD,
      "to_date":toD,
      "db_name":localStorage.getItem('db_name'),
      "user_code":1
  })

  localStorage.removeItem('fuel_report_data');
  localStorage.setItem("fuel_report_data", body);   
  
  for (let i = 0; i < this.Vehicles.length; i++) {
    if (this.Vehicles[i].code == vehicleName) {
      this.vehicle_name_to_send = this.Vehicles[i].label;
    }
  }

  for (let i = 0; i < this.Partys.length; i++) {
    if (this.Partys[i].code == partyName) {
      this.party_name_to_send = this.Partys[i].name;
    }
  }

    if (partyName == 0) {
      this.router.navigate(['/main/fuel/f_vehicle',this.party_name_to_send,this.vehicle_name_to_send,fromD,toD]);
    }
    else  if (vehicleName == 0) {
      this.router.navigate(['/main/fuel/f_party',this.party_name_to_send,this.vehicle_name_to_send,fromD,toD]);
    }
    else{
      this.router.navigate(['/main/fuel/f_vehicleParty',this.party_name_to_send,this.vehicle_name_to_send,fromD,toD]);
    }
  }

  

  ngOnInit() {
    this.apicall.fetchVehiclesData().subscribe((response) => {this.vehiclesData(response.json())});
    this.apicall.fetchFuelParty().subscribe((response) => {this.fuelParty(response.json())});
  }

  vehiclesData(res){
    console.log(res);
    this.Vehicles = res.vehicles_data;
  }

  fuelParty(res){
    console.log(res);
    this.Partys = res.parties_data;
  }

}
