import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor() {
    window.addEventListener('load', function() {
      var status = document.getElementById("status");
      var log = document.getElementById("log");
    
      function updateOnlineStatus(event) {
        var condition = navigator.onLine ? "" : "No internet connection";
    
        status.className = condition;
        status.innerHTML = condition.toUpperCase();
    
        // log.insertAdjacentHTML("beforeend", "Event: " + event.type + "; Status: " + condition);
      }
    
      window.addEventListener('online',  updateOnlineStatus);
      window.addEventListener('offline', updateOnlineStatus);
    });
   }

  ngOnInit() {
    
  }

  
}
