import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiCallingService } from '../../../apiCalling.service';
import { Router, NavigationEnd, NavigationCancel, NavigationStart, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import {ExcelService} from '../../../excel.service';

@Component({
  selector: 'app-vehicle2',
  templateUrl: './vehicle2.component.html',
  styleUrls: ['./vehicle2.component.css'],
  providers:[ApiCallingService]
})
export class Vehicle2Component implements OnInit, AfterViewInit {
  showNoData: number;

  print():void{
    let printContents, popupWin;
    printContents = document.getElementById('table1').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Wheelstracker</title>
          <style>
          th, td
          {
            border-right: 1px solid #efefef;
            border-bottom: 1px solid #efefef;
          }
          .balaji
          {
            border-top: 1px solid #efefef;
          }
          .table
          {
            border-left: 1px solid #efefef;
          }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  loaded:Promise<boolean>;
  sum1; 
  sum2;   
  sum3;
  sum_final = 0;
  sub: any;
  loading: boolean;
  driver_name: any;
  vehicle_name: any;
  from_date: any;
  to_date: any;
  vehicle_no: any;
  ngAfterViewInit(): void {}
  
  getsum1(){
    for(let i=0; i< this.driverData1.length; i++) {
       this.sum_final= +this.sum_final + +(+(+this.driverData1[i].short_rate + +this.driverData1[i].fuel_rate + +this.driverData1[i].advance + +this.driverData1[i].expense) - (+this.driverData1[i].short * +this.driverData1[i].freight_rate));
    }
    return this.sum_final;
  }
  getSum() {
     this.sum1 = 0;
     this.sum2 = 0;
     this.sum3 = 0;
    
    if (this.driverData1 != undefined) {
      for(let i = 0; i < this.driverData1.length; i++) {
        this.sum1 = +this.driverData1[i].short_rate; 
      }
    }
    if (this.driverData3 != undefined) {
      for(let i = 0; i < this.driverData3.length; i++) {
        this.sum2 = +this.sum2 + +this.driverData3[i].amount;
      }
    }
    if (this.driverData2 != undefined) {
      for(let i = 0; i < this.driverData2.length; i++) {
        this.sum3 = +this.sum3 + +this.driverData2[i].amount;
      }
    }
    
  }

  driverHeading1: any[] = [ 
    {
      "label1": "SN",
      "label2": "Date",
      "label3": "Billty No.",
      "label4": "From",
      "label5": "To",
      "label6": "Challan WT.",
      "label7": "Received WT.",
      "label8": "Shortage WT.",
      "label9": "Advance Cash",
      "label10": "Diesel",
      "label11": "Driver Name",
      "label12": "Party",
      "label13": "Fr. Amount"
    }
  ];

  driverData1: any[] = [];

  driverHeading2: any[] = [
    {
      "label1": "SN",
      "label2": "Date",
      "label3": "Challan No.",
      "label4": "Party Name",
      "label5": "Quantity",
      "label6": "Rate",
      "label7": "Remarks",
      "label8": "Amount"
    }
  ];

  driverData2: any[] = [];

  driverHeading3: any[] = [
    {
      "label1": "SN",
      "label2": "Date",
      "label3": "Challan No.",
      "label4": "Party Name",
      "label5": "Remarks",
      "label6": "Amount"
    }
  ];

  driverData3: any[] = [];

  // billty_data1:any;

  constructor(private excelService:ExcelService, private apicall: ApiCallingService, private route: ActivatedRoute, private router: Router) {
    this.sub = this.route.params.subscribe(params => {
      this.loading = true;
      this.driver_name = params['dname'];
      this.vehicle_name = params['vcode'];
      this.vehicle_no = params['dcode'];
      this.from_date = params['from'];
      this.to_date = params['to'];
      this.ngOnInit();
    });
   }

   exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.driverData1, 'sample');
    this.excelService.exportAsExcelFile(this.driverData2, 'sample');
    this.excelService.exportAsExcelFile(this.driverData3, 'sample');
 }

  ngOnInit() {
    this.apicall.fetchReportOfDriverVehicle().subscribe((response) => {this.reportOfVehicle2(response.json())});
  }


  reportOfVehicle2(res){
    if (res.success == "false") {
      this.showNoData = 1;
    }
    else {
      console.log(res);
      this.driverData1 = res.billty_data;
      this.driverData2 = res.fuel_entry_data;
      this.driverData3 = res.auto_parts_data;
      this.loaded=res;
      this.getSum();
      this.getsum1();
      this.showNoData = 0;
    }
    this.loading = false;

  }

}
