import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel, NavigationStart, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { ApiCallingService } from '../../../apiCalling.service';
import {ExcelService} from '../../../excel.service';

@Component({
  selector: 'app-driver-vehicle',
  templateUrl: './driver-vehicle.component.html',
  styleUrls: ['./driver-vehicle.component.css'],
  providers:[ApiCallingService]
})
export class DriverVehicleComponent implements OnInit, AfterViewInit {
  showNoData: number;

  print():void{
    let printContents, popupWin;
    printContents = document.getElementById('table1').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Wheelstracker</title>
          <style>
          th, td
          {
            border-right: 1px solid #efefef;
            border-bottom: 1px solid #efefef;
            padding: 20px;
          }
          .balaji
          {
            border-top: 1px solid #efefef;
          }
          .table
          {
            border-left: 1px solid #efefef;
          }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  sub: any;
  loading: boolean;
  driver_name: any;
  vehicle_name: any;
  from_date: any;
  to_date: any;
  ngAfterViewInit(): void {}

  driverHeading1: any[] = [
    {
      "label1": "SN",
      "label2": "Date",
      "label3": "Billty No.",
      "label4": "From",
      "label5": "To",
      "label6": "Challan WT.",
      "label7": "Received WT.",
      "label8": "Shortage WT.",
      "label9": "Advance Cash",
      "label10": "Diesel QTY.",
      "label11": "Party"
    }
  ];

  driverData1: any[] = [];

  constructor(private excelService:ExcelService, private apicall: ApiCallingService, private route: ActivatedRoute, private router: Router) {
    this.sub = this.route.params.subscribe(params => {
      this.loading = true;
      this.driver_name = params['dname'];
      this.vehicle_name = params['vcode'];
      this.from_date = params['from'];
      this.to_date = params['to'];
      this.ngOnInit();
    });
   }

   exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.driverData1, 'sample');
 }

  ngOnInit() {
    console.log("inn party");
    this.apicall.fetchReportOfDriverVehicle().subscribe((response) => {this.reportOfDriverVehicle(response.json())});
  }

  reportOfDriverVehicle(res){
    if (res.success == "false") {
      this.showNoData = 1;
    }
    else {
      console.log(res);
      this.driverData1 = res.billty_data;
      this.showNoData = 0;
    }    
    this.loading = false;
  }

}
