import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.component';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { AccordionModule } from 'ngx-bootstrap';
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { DatePipe } from '@angular/common';
import { NgxPasswordToggleModule } from 'ngx-password-toggle';
import { FilterPipeModule } from 'ngx-filter-pipe';


import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './main/header/header.component';
import { DriverComponent } from './main/driver/driver.component';
import { TransportComponent } from './main/transport/transport.component';
import { AutoPartsComponent } from './main/auto-parts/auto-parts.component';
import { FuelComponent } from './main/fuel/fuel.component';
import { VehicleComponent } from './main/fuel/vehicle/vehicle.component';
import { PartyComponent } from './main/fuel/party/party.component';
import { VehiclePartyComponent } from './main/fuel/vehicle-party/vehicle-party.component';
import { Driver1Component } from './main/driver/driver1/driver1.component';
import { Party1Component } from './main/auto-parts/party1/party1.component';
import { TransporterPartyComponent } from './main/transport/transporter-party/transporter-party.component';
import { TransporterVehicleComponent } from './main/transport/transporter-vehicle/transporter-vehicle.component';
import { DriverVehicleComponent } from './main/driver/driver-vehicle/driver-vehicle.component';
import { Vehicle2Component } from './main/driver/vehicle2/vehicle2.component';
import { Vehicle1Party1Component } from './main/auto-parts/vehicle1-party1/vehicle1-party1.component';
import { Vehicle1Component } from './main/auto-parts/vehicle1/vehicle1.component';
import { AuthGuard } from './guard.1/auth.guard';
import { SearchPipe } from './search.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    HeaderComponent,
    DriverComponent,
    TransportComponent,
    AutoPartsComponent,
    FuelComponent,
    VehicleComponent,
    PartyComponent,
    VehiclePartyComponent,
    Party1Component,
    Vehicle1Component,
    Vehicle1Party1Component,
    Driver1Component,
    Vehicle2Component,
    DriverVehicleComponent,
    TransporterPartyComponent,
    TransporterVehicleComponent,
    SearchPipe,
    
    
  ],
  imports: [
    BrowserModule,
    HttpModule,
    RouterModule,
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    NgxPasswordToggleModule,
    FilterPipeModule,
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [DatePipe, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
