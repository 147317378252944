import { FormGroup } from '@angular/forms';
import { Component, OnInit, Injectable } from '@angular/core';
import { ApiCallingService } from '../apiCalling.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers:[ApiCallingService]
})
export class LoginComponent implements OnInit {

  mobileNumber:any="";
  password:any;
  message: any= "";
  validation = 1;

  constructor(private apicall: ApiCallingService, private router: Router) {}

  login_path = "";

  sendData( num, pwd ){
    if (num.length >10 || num.length <10) {
      this.message = "Enter Valid Mobile Number."
      this.validation = 0;
      return;
    }
    this.apicall.fetchLoginValidate(num, pwd).subscribe((response) => {this.loginValidate(response.json())});
  }

  ngOnInit() {  
    if (localStorage.getItem('isLoggedin') && localStorage.getItem('user_code')) {
      this.router.navigate(['/main']);
    }
  }

  onLoggedin() {
    localStorage.setItem('isLoggedin', 'true');
  }
  
  validatemobile(event)
  {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }

  loginValidate(res){

    if (res.success == "true") {
      // localStorage.setItem('token', res.token);
      // localStorage.setItem('db_name', res.user_data.db_name);
      localStorage.setItem('isLoggedin','true');
      localStorage.setItem('user_code', res.user_data.code);
      this.router.navigate(['/main']);
      
    }
    else{
      this.message =  res.message;
      this.validation = 0;
    }
  }
}
