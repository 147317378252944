import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApiCallingService } from '../../apiCalling.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.css'],
  providers:[ApiCallingService]
})
export class TransportComponent implements OnInit {

  today=new Date();
  isCollapsed = false;
  step = 0;
  Partys: any[] = [];
  Vehicles: any[] = [];
  Routess: any[] = [];
  Owner : any[] = [];
  table_path = "";
  party_name_to_send: any = "0";
  vehicle_name_to_send: any = "0";
  owner_name_to_send: any = "0";
  route_name_to_send: any = "0";
  message: string;
  validation: number = 1;
  vehicle_no_to_send: any = "0";
  ownerName: any = "";
  valid: number = 1;
  

  constructor(private datePipe: DatePipe, private router: Router, private apicall: ApiCallingService) {
    
   }

   setOwnerName(code){
     
     if (code == 0) {
      this.ownerName = 0;
     }
     for (let i = 0; i < this.Vehicles.length; i++) {
      if (code == this.Vehicles[i].code) {
        this.ownerName = this.Vehicles[i].owner_name;
      }
       
     }
     console.log(this.ownerName);
     var a = document.getElementById('owner1') as HTMLInputElement;
     a.value = this.ownerName;
     
   }
  sendData( fromDate, toDate, partyName, vehicleName, route, ownerName ){
    console.log( fromDate, toDate, partyName, vehicleName, route, ownerName );
    
    var fromD = fromDate.split("-").reverse().join("-");
    var toD = toDate.split("-").reverse().join("-");
    console.log(fromDate,toDate);
    if( toD < fromD){
      this.message = "From is greater than To";
      this.valid = 0;
      return;
    }

    if (partyName == 0) {
      this.message = "Party is required";
      this.validation = 0;
      return;
    }
    
    for (let i = 0; i < this.Vehicles.length; i++) {
      if (this.Vehicles[i].code == vehicleName) {
        this.vehicle_name_to_send = this.Vehicles[i].label;
        this.vehicle_no_to_send = this.Vehicles[i].vehicle_no;
      }
    }
  
    for (let i = 0; i < this.Partys.length; i++) {
      if (this.Partys[i].code == partyName) {
        this.party_name_to_send = this.Partys[i].name;
      }
    }

    for (let i = 0; i < this.Routess.length; i++) {
      if (this.Routess[i].code == route) {
        this.route_name_to_send = this.Routess[i].mine_name;
      }
    }
  
    for (let i = 0; i < this.Owner.length; i++) {
      if (this.Owner[i].code == ownerName) {
        this.owner_name_to_send = this.Owner[i].owner_name;
      }
    }

    
    let body = JSON.stringify({
      "cross_key":"1",
      "vehicle_code":vehicleName,
      "party_code":partyName,
      "owner_name":ownerName,
      "route_code":route,
      "from_date":fromD,
      "to_date":toD,
      "db_name":localStorage.getItem('db_name'),
      "user_code":1
  })
  localStorage.removeItem('transporter_report_data');
  localStorage.setItem("transporter_report_data", body);
    if (vehicleName == 0) {
      this.router.navigate(['/main/transporters/t_party',this.party_name_to_send,this.vehicle_name_to_send,this.owner_name_to_send,this.route_name_to_send,fromD,toD]);
    }
    else {
      this.router.navigate(['/main/transporters/t_vehicle',this.party_name_to_send,this.vehicle_name_to_send,this.vehicle_no_to_send,this.owner_name_to_send,this.route_name_to_send,fromD,toD]);
    }
  }

  ngOnInit() {
    this.apicall.fetchVehiclesData().subscribe((response) => {this.vehiclesData(response.json())});
    this.apicall.fetchVehiclesData().subscribe((response) => {this.ownersData(response.json())});
    this.apicall.fetchTransporterParty().subscribe((response) => {this.transporterParty(response.json())});
    this.apicall.fetchRouteCommision().subscribe((response) => {this.routeCommision(response.json())});
  }

  vehiclesData(res){
    console.log(res);
    this.Vehicles = res.vehicles_data;
  }

  ownersData(res){
    console.log(res);
    this.Owner = res.vehicles_data;
  }

  transporterParty(res){
    console.log(res);
    this.Partys = res.parties_data;
  }

  routeCommision(res){
    console.log(res);
    this.Routess = res.route_commision_data;
  }

}
