import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiCallingService } from '../../../apiCalling.service';
import { Router, NavigationEnd, NavigationCancel, NavigationStart, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import {ExcelService} from '../../../excel.service';

@Component({
  selector: 'app-vehicle1-party1',
  templateUrl: './vehicle1-party1.component.html',
  styleUrls: ['./vehicle1-party1.component.css'],
  providers:[ApiCallingService]
})
export class Vehicle1Party1Component implements OnInit, AfterViewInit {
  showNoData: number;

  print():void{
    let printContents, popupWin;
    printContents = document.getElementById('table1').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Wheelstracker</title>
          <style>
          th, td
          {
            border-right: 1px solid #efefef;
            border-bottom: 1px solid #efefef;
            padding: 20px;
          }
          .balaji
          {
            border-top: 1px solid #efefef;
          }
          .table
          {
            border-left: 1px solid #efefef;
          }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  loaded:Promise<boolean>;
  sum1;   
  sub: any;
  loading: boolean;
  party_name: any;
  vehicle_name: any;
  from_date: any;
  to_date: any;
  ngAfterViewInit(): void {}

  getSum() {
     this.sum1 = 0;
    for(let i = 0; i < this.autoData1.length; i++) {
      this.sum1 = +this.sum1 + +this.autoData1[i].amount;
    }
  }

  autoHeading1: any[] = [
    {
      "label1": "SN",
      "label2": "Date",
      "label3": "Challan No.",
      "label4": "Remarks",
      "label5": "Amount"
    }
  ];

  autoData1: any[] = [];

  constructor(private excelService:ExcelService, private apicall: ApiCallingService, private route: ActivatedRoute, private router: Router) {
    this.sub = this.route.params.subscribe(params => {
      this.loading = true;
      this.party_name = params['pname'];
      this.vehicle_name = params['vcode'];
      this.from_date = params['from'];
      this.to_date = params['to'];
      this.ngOnInit();
    });
   }

   exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.autoData1, 'sample');
 }

  ngOnInit() {
    console.log("inn party");
    this.apicall.fetchReportOfAutoParts().subscribe((response) => {this.reportOfAutoPartsVehicle1Party1(response.json())});
  }

  reportOfAutoPartsVehicle1Party1(res){
    if (res.success == "false") {
      this.showNoData = 1;
    }
    else {
      console.log(res);
      this.autoData1 = res.auto_parts_data;
      this.loaded=res;
      this.getSum();
      this.showNoData = 0;
    }
    this.loading = false;
  }

}
