import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class ApiCallingService {
    
    admin_login_validate = 'http://reports.wheelstracker.in/apis/admin_login_validate.php';
    fetch_vehicles_data = 'http://reports.wheelstracker.in/apis/fetch_vehicles_data.php';
    fetch_fuel_party = 'http://reports.wheelstracker.in/apis/fetch_fuel_party.php';
    fetch_auto_parts_party = 'http://reports.wheelstracker.in/apis/fetch_auto_parts_party.php';
    fetch_drivers_data = 'http://reports.wheelstracker.in/apis/fetch_drivers_data.php';
    fetch_transporter_party = 'http://reports.wheelstracker.in/apis/fetch_transporter_party.php';
    fetch_route_commision = 'http://reports.wheelstracker.in/apis/fetch_route_commision.php';
    fetch_report_of_fuel_party = 'http://reports.wheelstracker.in/apis/fetch_report_of_fuel_party.php';
    fetch_report_of_auto_parts = 'http://reports.wheelstracker.in/apis/fetch_report_of_auto_parts.php';
    fetch_driver_vehicle_report = 'http://reports.wheelstracker.in/apis/fetch_driver_vehicle_report.php';
    fetch_report_of_transporter = 'http://reports.wheelstracker.in/apis/fetch_report_of_transporter.php';

    token: any;
    db_name: any;

  constructor(private http: Http,) { 
    // this.token = localStorage.getItem('token');
    this.db_name = localStorage.getItem('db_name');

  }

  

  fetchLoginValidate(mobileNumber, password) {
    let Myheaders = new Headers();
    Myheaders.append('content-type','application/json');
    let body = JSON.stringify({
      "cross_key":"1",
      "mobile":mobileNumber,
      "password":password,
      "db_name":this.db_name
    })
    return this.http.post(this.admin_login_validate,body,{headers:Myheaders});
  }

  fetchVehiclesData() {
    let Myheaders = new Headers();
    Myheaders.append('content-type','application/json');    
    // Myheaders.append('token', this.token);
    let body = JSON.stringify({
      "cross_key":"1",
      "vehicle_code":"0",
      "user_code":"1",
      "db_name":this.db_name

    })
    return this.http.post(this.fetch_vehicles_data,body,{headers:Myheaders});
  }

  fetchFuelParty() {
    let Myheaders = new Headers();
    Myheaders.append('content-type','application/json');
    // Myheaders.append('token', this.token);
    let body = JSON.stringify({
      "cross_key":"1",
      // "party_code":"0",
      "user_code":"1",
      "db_name":this.db_name
    })
    return this.http.post(this.fetch_fuel_party,body,{headers:Myheaders});
  }

  fetchAutoPartsParty() {
    let Myheaders = new Headers();
    Myheaders.append('content-type','application/json');
    // Myheaders.append('token', this.token);    
    let body = JSON.stringify({
      "cross_key":"1",
      "db_name":this.db_name
    })
    return this.http.post(this.fetch_auto_parts_party,body,{headers:Myheaders});
  }  

  fetchDriversData() {
    let Myheaders = new Headers();
    Myheaders.append('content-type','application/json');
    // Myheaders.append('token', this.token);    
    let body = JSON.stringify({
      "cross_key":"1",
      "driver_code":"0",
      "user_code":"1",
      "db_name":this.db_name
    })
    return this.http.post(this.fetch_drivers_data,body,{headers:Myheaders});
  }

  fetchTransporterParty() {
    let Myheaders = new Headers();
    Myheaders.append('content-type','application/json');
    // Myheaders.append('token', this.token);    
    let body = JSON.stringify({
      "cross_key":"1",
      "user_code":"1",
      "db_name":this.db_name
    })
    return this.http.post(this.fetch_transporter_party,body,{headers:Myheaders});
  }

  fetchRouteCommision() {
    let Myheaders = new Headers();
    Myheaders.append('content-type','application/json');
    // Myheaders.append('token', this.token);    
    let body = JSON.stringify({
      "cross_key":"1",
      "route_commision_code":"0",
      "user_code":"1",
      "db_name":this.db_name
    })
    return this.http.post(this.fetch_route_commision,body,{headers:Myheaders});
  }

  fetchReportOfFuelParts(){
    let Myheaders = new Headers();
    Myheaders.append('content-type','application/json');
    // Myheaders.append('token', this.token);    
    let data = localStorage.getItem('fuel_report_data');
    return this.http.post(this.fetch_report_of_fuel_party,data,{headers:Myheaders});
  }

  fetchReportOfAutoParts(){
    let Myheaders = new Headers();
    Myheaders.append('content-type','application/json');
    // Myheaders.append('token', this.token);    
    let data = localStorage.getItem('auto_report_data');
    return this.http.post(this.fetch_report_of_auto_parts,data,{headers:Myheaders});
  }

  fetchReportOfDriverVehicle(){
    let Myheaders = new Headers();
    Myheaders.append('content-type','application/json');
    // Myheaders.append('token', this.token);  
    let data = localStorage.getItem('drivers_report_data');
    return this.http.post(this.fetch_driver_vehicle_report,data,{headers:Myheaders});
  }

  fetchReportOfTransporter(){
    let Myheaders = new Headers();
    Myheaders.append('content-type','application/json');
    // Myheaders.append('token', this.token); 
    let data = localStorage.getItem('transporter_report_data');
    return this.http.post(this.fetch_report_of_transporter,data,{headers:Myheaders});
  }
}
