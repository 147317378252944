import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isMenuCollapse:boolean;
  categories: any[] = 
  [
    {
      "label": "drivers",
      "path": "drivers"
    },
    {
      "label": "transporters",
      "path": "transporters"
    },
    {
      "label": "auto parts",
      "path": "autoparts"
    },
    {
      "label": "fuel",
      "path": "fuel"
    }
  ];

  public isCollapsed = false;

  constructor(private router: Router) { }

  ngOnInit() {
  }
  onLoggedout() {
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('user_code');
    this.router.navigate(['login']);
}
}
