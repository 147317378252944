import { Component, OnInit, AfterViewInit} from '@angular/core';
import { Router, NavigationEnd, NavigationCancel, NavigationStart, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { ApiCallingService } from '../../../apiCalling.service';
import {ExcelService} from '../../../excel.service';

@Component({
  selector: 'app-transporter-party',
  templateUrl: './transporter-party.component.html',
  styleUrls: ['./transporter-party.component.css'],
  providers:[ApiCallingService]
})
export class TransporterPartyComponent implements OnInit, AfterViewInit {
  showNoData: number;
  sum2: number;
  overAllNetSum: number = 0;
  overAllPayNetSum: number = 0;

  print():void{
    let printContents, popupWin;
    printContents = document.getElementById('table1').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Wheelstracker</title>
          <style>
          th, td
          {
            border-right: 1px solid #efefef;
            border-bottom: 1px solid #efefef;
          }
          .balaji
          {
            border-top: 1px solid #efefef;
          }
          .table
          {
            border-left: 1px solid #efefef;
          }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
 
  ngAfterViewInit(): void {}
  loaded:Promise<boolean>;
  sum1;
  sub: any;
  loading: boolean;
  party_name: any;
  vehicle_name: any;
  owner_name: any;
  route_name: any;
  from_date: any;
  to_date: any;
  
  getSumGross(data,one,two) {
    this.sum1 = 0;
   for(let i = 0; i < data.length; i++) {
     this.sum1 = +this.sum1 + (+(data[i][one]) * +data[i][two]);
   }
   return this.sum1;
 }

  getSum(data,column) {
    this.sum1 = 0;
    for(let i = 0; i < data.length; i++) {
      this.sum1 = +this.sum1 + +data[i][column];
    }
    return this.sum1;
  }

  getSumNet(data,column) {
  this.sum1 = 0;
  for(let i = 0; i < data.length; i++) {
    this.sum1 = +this.sum1 + +data[i][column];
  }
  return this.sum1;
  }

  getSum1(data, column1, column2, column3, column4) {
  this.sum1 = 0;
  this.sum2 = 0;
  for(let i = 0; i < data.length; i++) {
    this.sum1 =+this.sum1+ +data[i][column1]+ +data[i][column2]+ +data[i][column3]+ +data[i][column4];
  }
  return this.sum1;
  }

  getSum2(data, column1, column2, column3, column4, column5, column6) {
  this.sum1 = 0;
  for(let i = 0; i < data.length; i++) {
    this.sum1 =+this.sum1+ (+(+data[i][column1]) * (+data[i][column2]) - (+data[i][column3]+ +data[i][column4]+ +data[i][column5]+ +data[i][column6]));
  }
  return this.sum1;
  }

  transporterData1: any[] = [];

  constructor(private excelService:ExcelService, private apicall: ApiCallingService, private route: ActivatedRoute, private router: Router) {
    this.sub = this.route.params.subscribe(params => {
      this.loading = true;
      this.party_name = params['pname'];
      this.vehicle_name = params['vcode'];
      this.owner_name = params['oname'];
      this.route_name = params['rname'];
      this.from_date = params['from'];
      this.to_date = params['to'];
      this.overAllNetSum  = 0;
      this.overAllPayNetSum  = 0;
      this.apicall.fetchReportOfTransporter().subscribe((response) => {this.reportOfTransporterParty(response.json())});
      this.ngOnInit();
    });
   }

   exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.transporterData1, 'sample');
  }

  totoalSumNetDeduct(){
    for (let i = 0; i < this.transporterData1.length; i++) {
      if (this.transporterData1[i].billty_rows.length > 0 ||  this.transporterData1[i].billty_rows != undefined){
      for (let j = 0; j < this.transporterData1[i].billty_rows.length; j++) {
        if (this.transporterData1[i].billty_rows[j].route_billty_data.length > 0 ||  this.transporterData1[i].billty_rows[j].route_billty_data != undefined){
          for (let k = 0; k < this.transporterData1[i].billty_rows[j].route_billty_data.length; k++) {
            this.overAllNetSum =+this.overAllNetSum+ +this.transporterData1[i].billty_rows[j].route_billty_data[k]['short']+ +this.transporterData1[i].billty_rows[j].route_billty_data[k]['diesel']+ +this.transporterData1[i].billty_rows[j].route_billty_data[k]['advance']+ +this.transporterData1[i].billty_rows[j].route_billty_data[k]['expense'];
            
            this.overAllPayNetSum =+this.overAllPayNetSum+ (+(+this.transporterData1[i].billty_rows[j].route_billty_data[k]['min_qty']) * (+this.transporterData1[i].billty_rows[j].route_billty_data[k]['freight_rate']) - (+this.transporterData1[i].billty_rows[j].route_billty_data[k]['short']+ +this.transporterData1[i].billty_rows[j].route_billty_data[k]['advance']+ +this.transporterData1[i].billty_rows[j].route_billty_data[k]['diesel']+ +this.transporterData1[i].billty_rows[j].route_billty_data[k]['expense']));
            }              
          }          
        }   
             
      }      
    } 
  }

  ngOnInit() {
    console.log("inn party");
  }

  reportOfTransporterParty(res){
    if (res.success == "false") {
      this.showNoData = 1;
    }
    else {
      console.log(res);
      this.transporterData1 = res.billty_data;
      this.totoalSumNetDeduct();
      this.loaded=res;
      this.showNoData = 0;
      }
    this.loading = false;
  }
}
