import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiCallingService } from '../../../apiCalling.service';
import { Router, NavigationEnd, NavigationCancel, NavigationStart, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import {ExcelService} from '../../../excel.service';

@Component({
  selector: 'app-driver1',
  templateUrl: './driver1.component.html',
  styleUrls: ['./driver1.component.css'],
  providers:[ApiCallingService]
})
export class Driver1Component implements OnInit, AfterViewInit {
  getDateDiff: (from_date: any, to_date: any) => number;  
  showNoData: number;
  total: number;

  print():void{
    let printContents, popupWin;
    printContents = document.getElementById('table1').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Wheelstracker</title>
          <style>
          th, td
          {
            border-right: 1px solid #efefef;
            border-bottom: 1px solid #efefef;
            padding: 20px;
          }
          .balaji
          {
            border-top: 1px solid #efefef;
          }
          .table
          {
            border-left: 1px solid #efefef;
          }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  loaded:Promise<boolean>;
  sum;
  sum1;
  sum2;
  sub: any;
  loading: boolean;
  driver_name: any;
  vehicle_name: any;
  from_date: any;
  to_date: any;
  ngAfterViewInit(): void {}

  getSum() {
    this.sum1 = 0;
    this.sum2 = 0;
    for(let i = 0; i < this.driverData1.length; i++) {
      if(this.driverData1[i].advance != undefined)
      this.sum1 = +this.sum1 + +this.driverData1[i].advance;
    }
    for(let i = 0; i < this.trips.length; i++) {
      this.sum2 = +this.sum2+ +(+(+this.trips[i].trips_count * +this.trips[i].commision));
    }
  }
  getAbs() {
    this.sum = 0;
    for(let i = 0; i < this.driverData1.length; i++) {
    if(this.driverData1[i].is_trip == 0 && this.driverData1[i].is_absent == 1) {
      this.sum = +this.sum+ +1;
      }
    }
    
  }

  driverHeading1: any[] = [
    {
      "label1": "SN",
      "label2": "Date",
      "label3": "Billty No.",
      "label4": "From",
      "label5": "To",
      "label6": "Challan WT.",
      "label7": "Received WT.",
      "label8": "Shortage WT.",
      "label9": "Advance Cash",
      "label10": "Diesel QTY.",
      "label11": "Vehicle Label",
      "label12": "Party"
    }
  ];

  

  driverData1: any[] = [];

  trips: any[] = [];

  absent: any;
  salary: any;

  constructor(private excelService:ExcelService, private apicall: ApiCallingService, private route: ActivatedRoute, private router: Router) {
    this.sub = this.route.params.subscribe(params => {
      this.loading = true;
      this.driver_name = params['dname'];
      this.vehicle_name = params['vcode'];
      this.from_date = params['from'];
      this.to_date = params['to'];
      this.ngOnInit();
    });

   
  }

   exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.driverData1, 'sample');
    this.excelService.exportAsExcelFile(this.trips, 'sample');
 }

  ngOnInit() {
    console.log("inn party");
    this.apicall.fetchReportOfDriverVehicle().subscribe((response) => {this.reportOfDriver1(response.json())});

    var day_start:any = new Date(this.from_date);
    var day_end:any = new Date(this.to_date);
    var total_days = (day_end - day_start) / (1000 * 60 * 60 * 24);
    this.total = Math.round(+total_days+ +1);
  }

  reportOfDriver1(res){
    if (res.success == "false") {
      this.showNoData = 1;
    }
    else {
      console.log(res);
      this.driverData1 = res.billty_data;
      this.trips = res.trips_count;
      this.absent = res.absent_count;
      this.salary = res.driver_salary;
      this.loaded=res;
      this.getSum();
      this.getAbs();
      this.showNoData = 0;
    }
    this.loading = false;
  }
}
