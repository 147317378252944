import { RouterModule, Routes    } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { FuelComponent } from './main/fuel/fuel.component';
import { AutoPartsComponent } from './main/auto-parts/auto-parts.component';
import { TransportComponent } from './main/transport/transport.component';
import { DriverComponent } from './main/driver/driver.component';
import { PartyComponent } from './main/fuel/party/party.component';
import { Party1Component } from './main/auto-parts/party1/party1.component';
import { Driver1Component } from './main/driver/driver1/driver1.component';
import { TransporterPartyComponent } from './main/transport/transporter-party/transporter-party.component';
import { TransporterVehicleComponent } from './main/transport/transporter-vehicle/transporter-vehicle.component';
import { Vehicle1Party1Component } from './main/auto-parts/vehicle1-party1/vehicle1-party1.component';
import { Vehicle1Component } from './main/auto-parts/vehicle1/vehicle1.component';
import { VehiclePartyComponent } from './main/fuel/vehicle-party/vehicle-party.component';
import { VehicleComponent } from './main/fuel/vehicle/vehicle.component';
import { DriverVehicleComponent } from './main/driver/driver-vehicle/driver-vehicle.component';
import { Vehicle2Component } from './main/driver/vehicle2/vehicle2.component';
import { AuthGuard } from './guard.1/auth.guard';
        
const appRoutes: Routes = [
    // {path:'', redirectTo:'login',pathMatch:'full', canActivate: [AuthGuard]},
    {path:'login', component: LoginComponent},
    {path:'', redirectTo:'main',pathMatch:'full'},
    {path:'main', component: MainComponent,canActivate: [AuthGuard],
        children:[
            {path:'', redirectTo:'drivers',pathMatch:'full'},
            {path:'drivers', component: DriverComponent,
                children:[
                    {path:'d_driver/:dname/:vcode/:dcode/:from/:to', component: Driver1Component},
                    {path:'d_vehicle/:dname/:vcode/:dcode/:from/:to', component: Vehicle2Component},
                    {path:'d_vehicleDriver/:dname/:vcode/:dcode/:from/:to', component: DriverVehicleComponent}
                ]},
            {path:'transporters', component: TransportComponent,
                children:[
                    {path:'t_party/:pname/:vcode/:oname/:rname/:from/:to', component: TransporterPartyComponent},
                    {path:'t_vehicle/:pname/:vcode/:vno/:oname/:rname/:from/:to', component: TransporterVehicleComponent}
                ]},
            {path:'autoparts', component: AutoPartsComponent,
                children:[
                    {path:'a_party/:pname/:vcode/:from/:to', component: Party1Component},
                    {path:'a_vehicle/:pname/:vcode/:from/:to', component: Vehicle1Component},
                    {path:'a_vehicleParty/:pname/:vcode/:from/:to', component: Vehicle1Party1Component}
                ]},
            {path:'fuel', component: FuelComponent,
                children:[
                    {path:'f_party/:pname/:vcode/:from/:to', component: PartyComponent},
                    {path:'f_vehicle/:pname/:vcode/:from/:to', component: VehicleComponent},
                    {path:'f_vehicleParty/:pname/:vcode/:from/:to', component: VehiclePartyComponent}
                ]}
        ]
    },
    
    
];

@NgModule({
    imports:[
        RouterModule.forRoot(appRoutes, { useHash: true }),
        CommonModule
    ],
    exports: [RouterModule]

})


export class AppRoutingModule {

}


