import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel, NavigationStart, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { ApiCallingService } from '../../../apiCalling.service';
import {ExcelService} from '../../../excel.service';

@Component({
  selector: 'app-transporter-vehicle',
  templateUrl: './transporter-vehicle.component.html',
  styleUrls: ['./transporter-vehicle.component.css'],
  providers:[ApiCallingService]
})
export class TransporterVehicleComponent implements OnInit, AfterViewInit {
  showNoData: number;
  vehicle_no: any;

  print():void{
    let printContents, popupWin;
    printContents = document.getElementById('table1').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Wheelstracker</title>
          <style>
          th, td
          {
            border-right: 1px solid #efefef;
            border-bottom: 1px solid #efefef;
          }
          .balaji
          {
            border-top: 1px solid #efefef;
          }
          .table
          {
            border-left: 1px solid #efefef;
          }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
 
  ngAfterViewInit(): void {}
  loaded:Promise<boolean>;
  sum1;
  loading: boolean;
  sub: any;
  party_name: any;
  vehicle_name: any;
  owner_name: any;
  route_name: any;
  from_date: any;
  to_date: any;


  getSumGross(data,one,two) {
     this.sum1 = 0;
    for(let i = 0; i < data.length; i++) {
      this.sum1 = +this.sum1 + (+(data[i][one]) * +data[i][two]);
    }
    return this.sum1;
  }

  getSum(data,column) {
    this.sum1 = 0;
   for(let i = 0; i < data.length; i++) {
     this.sum1 = +this.sum1 + +data[i][column];
   }
   return this.sum1;
 }

 getSumNet(data,column) {
  this.sum1 = 0;
 for(let i = 0; i < data.length; i++) {
   this.sum1 = +this.sum1 + +data[i][column];
 }
 return this.sum1;
}

getSum1(data, column1, column2, column3, column4) {
  this.sum1 = 0;
  for(let i = 0; i < data.length; i++) {
    this.sum1 =+this.sum1+ +data[i][column1]+ +data[i][column2]+ +data[i][column3]+ +data[i][column4];
  }
  return this.sum1;
}

getSum2(data, column1, column2, column3, column4, column5, column6) {
  this.sum1 = 0;
  for(let i = 0; i < data.length; i++) {
    this.sum1 =+this.sum1+ (+(+data[i][column1]) * (+data[i][column2]) - (+data[i][column3]+ +data[i][column4]+ +data[i][column5]+ +data[i][column6]));
  }
  return this.sum1;
}

  transporterData1: any[] = [];

  constructor(private excelService:ExcelService, private apicall: ApiCallingService, private route: ActivatedRoute, private router: Router) {
    this.sub = this.route.params.subscribe(params => {
      this.loading = true;
      this.party_name = params['pname'];
      this.vehicle_name = params['vcode'];
      this.vehicle_no = params['vno'];
      this.owner_name = params['oname'];
      this.route_name = params['rname'];
      this.from_date = params['from'];
      this.to_date = params['to'];
      this.ngOnInit();
    }); 
    }

    exportAsXLSX():void {
      this.excelService.exportAsExcelFile(this.transporterData1, 'sample');
   }

  ngOnInit() {
    this.apicall.fetchReportOfTransporter().subscribe((response) => {this.reportOfTransporterVehicle(response.json())});
  }

  reportOfTransporterVehicle(res){
    if (res.success == "false") {
      this.showNoData = 1;
    }
    else {
      console.log(res);
      this.transporterData1 = res.billty_data;
      this.loaded=res;
      this.showNoData = 0;
    }
    this.loading = false;
  }

}
