import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiCallingService } from '../../../apiCalling.service';
import { Router, NavigationEnd, NavigationCancel, NavigationStart, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import {ExcelService} from '../../../excel.service';


@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css'],
  providers:[ApiCallingService]
})
export class VehicleComponent implements OnInit,AfterViewInit {
  showNoData: number;

  print():void{
    let printContents, popupWin;
    printContents = document.getElementById('table1').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Wheelstracker</title>
          <style>
          th, td
          {
            border-right: 1px solid #efefef;
            border-bottom: 1px solid #efefef;
            padding: 20px;
          }
          .balaji
          {
            border-top: 1px solid #efefef;
          }
          .table
          {
            border-left: 1px solid #efefef;
          }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  loaded:Promise<boolean>;
  sum;
  sum1;   
  sub: any;
  loading: boolean;
  party_name: any;
  vehicle_name: any;
  from_date: any;
  to_date: any;
  ngAfterViewInit(): void {}

  getSum() {
     this.sum = 0;
     this.sum1 = 0;
    for(let i = 0; i < this.fuelData1.length; i++) {
      this.sum1 = +this.sum1 + +this.fuelData1[i].amount;
      this.sum = +this.sum + +this.fuelData1[i].quantity;
    }
  }

  fuelHeading1: any[] = [
    {
      "label1": "SN",
      "label2": "Date",
      "label3": "Challan No.",
      "label4": "Party Name",
      "label5": "Fuel QTY.",
      "label6": "Rate",
      "label7": "Remarks",
      "label8": "Amount"
    }
  ];

  fuelData1: any[] = [];

  constructor(private excelService:ExcelService,private apicall: ApiCallingService, private route: ActivatedRoute, private router: Router) {
    this.sub = this.route.params.subscribe(params => {
      this.loading = true;
      this.party_name = params['pname'];
      this.vehicle_name = params['vcode'];
      this.from_date = params['from'];
      this.to_date = params['to'];
      this.ngOnInit();
    });
   }

   exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.fuelData1, 'sample');
 }

  ngOnInit() {
    this.apicall.fetchReportOfFuelParts().subscribe((response) => {this.reportOfFuelVehicle(response.json())});
  }

  reportOfFuelVehicle(res){
    if (res.success == "false") {
      this.showNoData = 1;
    }
   else{
    console.log(res);
    this.fuelData1 = res.fuel_entry_data;
    this.loaded=res;
    this.getSum();
    this.showNoData = 0;
   }
   this.loading = false;
  }

}
