import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApiCallingService } from '../../apiCalling.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.css'],
  providers:[ApiCallingService]
})
export class DriverComponent implements OnInit {

  today=new Date();
  step = 0;
  Drivers: any[] = [];
  Vehicles: any[] = [];
  table_path = "";
  driver_name_to_send: any = "0";
  vehicle_name_to_send: any = "0";
  vehicle_no_to_send: any = "0";
  message: string;
  validation: number = 1;
  valid: number = 1;

  constructor(private datePipe: DatePipe, private router: Router, private apicall: ApiCallingService) { }

  sendData( fromDate, toDate, driverName, vehicleName ){
    console.log( fromDate, toDate, driverName, vehicleName );
    if (driverName ==0 && vehicleName == 0) {
      this.message = "Driver or Vehicle is required";
      this.validation = 0;
      return;
    }
    var fromD = fromDate.split("-").reverse().join("-");
    var toD = toDate.split("-").reverse().join("-");
    if( toD < fromD){
      this.message = "From is greater than To";
      this.valid = 0;
      return;
   }
    let body = JSON.stringify({
      "cross_key":"1",
      "driver_code":driverName,
      "vehicle_code":vehicleName,
      "from_date":fromD,
      "to_date":toD,
      "db_name":localStorage.getItem('db_name'),
      "user_code":1
  })
  localStorage.removeItem('drivers_report_data');
  localStorage.setItem("drivers_report_data", body);

  for (let i = 0; i < this.Vehicles.length; i++) {
    if (this.Vehicles[i].code == vehicleName) {
      this.vehicle_name_to_send = this.Vehicles[i].label;
      this.vehicle_no_to_send = this.Vehicles[i].vehicle_no;
    }
  }

  for (let i = 0; i < this.Drivers.length; i++) {
    if (this.Drivers[i].code == driverName) {
      this.driver_name_to_send = this.Drivers[i].name;
    }
  }

    if (driverName == 0) {
      this.router.navigate(['/main/drivers/d_vehicle',this.driver_name_to_send,this.vehicle_name_to_send,this.vehicle_no_to_send,fromD,toD]);
    }
    else  if (vehicleName == 0) {
      this.router.navigate(['/main/drivers/d_driver',this.driver_name_to_send,this.vehicle_name_to_send,this.vehicle_no_to_send,fromD,toD]);
    }
    else{
      this.router.navigate(['/main/drivers/d_vehicleDriver',this.driver_name_to_send,this.vehicle_name_to_send,this.vehicle_no_to_send,fromD,toD]);
    }
  }

  ngOnInit() {
    this.apicall.fetchVehiclesData().subscribe((response) => {this.vehiclesData(response.json())});
    this.apicall.fetchDriversData().subscribe((response) => {this.driversData(response.json())});
    
  }

  vehiclesData(res){
    console.log(res);
    this.Vehicles = res.vehicles_data;
  }

  driversData(res){
    console.log(res);
    this.Drivers = res.drivers_data;
  }

}
